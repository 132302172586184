/*For testimonials*/
.animated-testi{
  /* margin: 20px 15px; */
  padding-bottom: 40px;
  font-family: fira sans,sans-serif;
  align-items: center;
  height: 600px;
}

.testimonials {
    display: flex;
    gap: 1rem;
    padding: 3rem 3rem;    
  }
  .left-t {
    display: flex;
    flex: 2 1;
    /* gap: 5px; */
    flex-direction: column;
    color: var(--theme-color);
    text-transform: uppercase;
  }
  .left-t>:nth-child(1) {
    color: black;
    font-weight: bold;
    text-transform: none;
    font-size: 20px;
  }
  .left-t>:nth-child(2), .left-t>:nth-child(3) {
    font-weight: 500;
    font-size: 47px;
    text-transform: none;
  }
  .left-t>:nth-child(4) {
    /* text-transform: none;
    text-align: justify;
    letter-spacing: 1px;
    line-height: 30px; */
    color: #5f5f5f;
    margin-bottom: 15px;
    padding-right: 17px;
    text-align: justify;
    margin-right: 20px;
    font-family: fira sans,sans-serif;
    text-transform: none;
  }
  .right-t {
    flex: 1;
    position: relative;
    margin-bottom: 20px;
  }
  .right-t>img {
    position: absolute;
    width: 17rem;
    height: 20.5rem;
    object-fit: cover;
    right: 0rem;
    top: 2rem;
  }
  .right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid #0f9099;
    background-color: transparent;
    right: 1rem;
    top: 0.9rem;
  }
  .right-t>:nth-child(2) {
  position: absolute;
  width: 17rem;
  height: 19.5rem;
  right: -1rem;
  top: 4rem;
  background: #9edbdf;
  margin-bottom: 25px;
  }
  .arrows {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: -8rem;
    left: -480px;
  }
  .arrows>img {
    width: 1.5rem;
    cursor: pointer;
  }
  
  @media screen and (max-width: 992px) {
    .figures > div > span:nth-last-of-type(1){
      font-size: large;
    }
    .figures > div > span:nth-last-of-type(2){
      font-size: small;
    }
    .testimonials {
      flex-direction: column;
    }
    .left-t>:nth-child(2), .left-t>:nth-child(3){
      font-size: 35px;
    }
    .right-t {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }
    .right-t>div {
      position: relative;
      display: none;
    }
    .right-t>img {
      top: 0;
      right: 0;
      position: relative;
      align-self: center;
    }
    .right-t>:last-child {
      display: block;
      bottom: 0;
      left: 0;
    }
    .animated-testi {
      height: 100%;
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 767px) {
    .left-t>:nth-child(4) {
     
      margin-bottom: 15px;
      padding-right: 0;
      margin-right: 0;
  }
  .testimonials {
    padding: 0;
}
    }

