/* Existing styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

h1 {
  text-align: center;
  margin: 20px;
}
.video-gallery-heading{
  font-weight: 700;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0 20px;
}

.video-item {
  height: 200px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.video-item:hover {
  transform: scale(1.05);
}

.thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.video-item p {
  padding: 10px;
  font-weight: bold;
  background-color: #f4f4f4;
  margin: 0;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  align-items: center;
}

.modal video {
  max-width: 100%;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #888;
  font-size: 36px;
  cursor: pointer;
  transition: color 0.3s;
}

.close-button:hover {
  color: #f00;
}

.close-button:focus {
  outline: none;
}