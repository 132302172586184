.socialimpact {    
    background-color: #f5f5f5;
    padding: 30px 0;
    text-align: center;
    height: 80vh;
}

.socialimpact h2 {
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
    font-family: "Libre Baskerville", serif;
    color: #983934;  
}

.socialimpact a {
    text-align: center;
    justify-content: center;
    display: flex;
    color: #660b08;    
}
.socialimpact a:hover {
    color: blue;
}
.iitm-initiative{
    color: #000;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 25px;
    font-family: "Libre Baskerville", serif;
}
.downloadreport{
    margin: 10px;
    padding: 10px;
}
@media screen and (max-width: 768px) {
    .socialimpact h2 {
        font-size: 18px;
    } 
    .iitm-initiative {
        font-size: 18px;
    }   
    .socialimpact {
        height: 100% !important;
    }
    .iitm-initiative {
        margin-bottom: 10px !important;
    }
    .team_04 {
        margin: 6px !important;
    }
    .downloadreport {
        margin: 2px !important;
        padding: 1px !important;
    }
    .socialimpact {
        padding: 9px 0 !important;
    }
}