@color-white: #ffffff;
@color-black: #252a32;
@color-light: #f1f5f8;
@color-red: #d32f2f;
@color-blue: #148cb8;

@box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);

*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	list-style: none;
	list-style-type: none;
	text-decoration: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: color-black;
  background: color-white;
}

.container {
  max-width: 80rem;
  width: 100%;
  /* padding : 4rem 2rem; */
  margin: 0 auto;
}

.main {
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .card {
    color: color-black;
    border-radius: 2px;
    background: color-white;
    box-shadow: box-shadow;

    &-image {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 70%;
      background: color-white;

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main {
    .container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }
  }
}
