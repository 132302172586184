*{
  padding: 0;
  margin: 0;box-sizing: border-box;
}
.gallery-title {
  font-family: 'Arbutus Slab', serif;
  font-weight: normal;
  color: #fff;
  line-height: 1.25;
  margin-top: 15%;
}

.image-count {
  position: absolute;
  bottom: 0;
  font-size: 14px;
  color: #fff;
}

.gallery-container {
  max-width: 900px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
  grid-gap: 48px;
  margin: 0 auto;
  height: 100%;
}

.gallery-card {
  cursor: pointer;
  position: relative;
  height: 0;
  padding-bottom: 120%;
  --offset-multiplier: 4px;
  transition: transform 0.6s ease;
  --translate: 0;
  transform: translate(var(--translate), var(--translate));        
}

.gallery-card:hover {
  --offset-multiplier: 4px;
  transition: transform 0.3s ease;
}

.gallery-card:hover .gallery-child {
  transform: translate(calc(var(--offset) * var(--offset-multiplier)), calc(var(--offset) * var(--offset-multiplier)));
}

.gallery-child {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  background: var(--card);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  transition: inherit;
  z-index: 5;
  /* background: linear-gradient(40.14deg, #4e318e 0, #0d6fb4 54.1%, #05a9e1 98.3%); */
  background: var(--theme-color);
}

.gallery-card .gallery-child:nth-child(1) {
  --offset: -1;
  z-index: 5;
}

.gallery-card .gallery-child:nth-child(2) {
  --offset: 0;
  z-index: 4;
}

.gallery-card .gallery-child:nth-child(3) {
  --offset: 1;
  z-index: 3;
}

.gallery-card .gallery-child:nth-child(4) {
  --offset: 2;
  z-index: 2;
}

.gallery-card .gallery-child:nth-child(5) {
  --offset: 3;
  z-index: 1;
}