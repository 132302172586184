*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.project-tech {
    color: var(--theme-color);
    text-align: center;
    font-family: "fira", sans-serif;
    margin: 20px;
    font-weight: 600;    
}

.tabs {
    list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
}
.tab {
	width: 33.33%;
    text-align: center;
}

.tab {
	background: #ccc;
	cursor: pointer;
	color: #404040;
	padding: 12px 15px;   

	&.tab--active {
		background: linear-gradient(to right, #3f9093, #59c5c9, #70b747);
		color: white;
        text-align: center;		
	}
}

.tab-content__section {
	background: #efefef;
    text-align: justify;

}
.project-tech-p {
	text-align: justify;
}
.proj-tab-section {
	padding: 10px;
}
.tab-sec-container ul li {
	border-radius: 2px;
}
.tabsec {
	width: 100%;
}

.proj-content {
	align-items: center !important;
	justify-content: center;
	text-align: center;
}
.proj-content h1{
    color: #781f19;
    font-weight: 600;
}

.probtn {
	width: 200px;
    height: 50px;
    padding: 6px 0;
    text-align: center;
    margin: 20px 10px;
    border-radius: 5px;
    font-weight: 500;    
    background: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;  
	color:  #000 !important;
	background: linear-gradient(to right, #3f9093, #59c5c9, #70b747);
	border: 1px solid #009688;
	
	/* color:  #FFEA11;
	border: 2px solid #009688; */
}
.probtn:hover {
    background: linear-gradient(to right,  #70b747, #59c5c9,  #3f9093);
}
.probtn.value{
    text-align: center;
    background: #009688;
    height: 100%;
    width: 0;
    border-radius: 25px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: 0.5s;
}

/***********New tab section ****************/
body {
    background-color: white;
    margin: 20px;    
}

h1 {
    color: white;
    font-weight: 500;
    text-align: center;
    margin: 60px 0;
}
.tabs {
    display: flex;
    justify-content: space-between;
    
}

.tabs button {
    font-size: 20px;
    background-color: lightgray;
    border: none;
    color: #888888;
    cursor: pointer;
    padding: 25px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease-out;
    border: 1px solid #387710;
}

.tabs button:hover {
    color: black;
    background-color: rgba(255, 255, 255, 0.15);
}

.tabs button:disabled {
    background: linear-gradient(to right, #3f9093, #59c5c9, #70b747);
    /* background: linear-gradient(245.39deg, #FC65ED -35.14%, #0500FF 143.06%); */
    color: white;
}

.title {
    color: black;
    font-size: medium;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;    
}

.content {
    padding: 50px 30px;
    background-color: white;
    font-weight: 300;
    line-height: 30px;
    font-size: 16px;
    text-align: justify;
    /* border: 1px solid #387710; */
    border-top: none;    
}
.content,
button {
    font-size: 17px;
}
.tab-row { 
    --mdb-gutter-x: 0 !important;
  }

@media only screen and (max-width: 960px) {

    .container {
        width: 100%;
    }

    .content {
        padding: 50px;
    }
}

.spin:hover {
    cursor: pointer;
}


.spin:focus, .spin:disabled {
    outline: none;
    background: #aaa;
  }
  
  /* This is the space for the spinner to appear, applied to the button */
  .spin {
    padding-left: 2.5em;
    display: block;
  }
  
  /* position of the spinner when it appears, you might have to change these values */
  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }
  
  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; 
    height: 1.5em; 
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #999; 
    border-bottom-color: #555; 
    animation: .8s linear infinite spinner; 
    transform: translate(-50%, -50%);
    will-change: transform;
  }