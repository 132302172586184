#cards_landscape_wrap-2 .card-flyer .image-box img {
    -webkit-transition: all .9s ease;
    -moz-transition: all .9s ease;
    -o-transition: all .9s ease;
    -ms-transition: all .9s ease;
    width: 100%;
    max-height: 250px;
}
#cards_landscape_wrap-2 .card-flyer .image-box {
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.50);
    border-radius: 5px;
}
#cards_landscape_wrap-2 .card-flyer .text-box {
    text-align: center;
}
#cards_landscape_wrap-2 .card-flyer {
    background: #FFFFFF;
    margin-top: 50px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.40);
}
#cards_landscape_wrap-2 .card-flyer {
    border-radius: 5px;
}
.load-more{
    background-color: var(--theme-color);
    border-radius: 5px;
    border-color:var(--theme-color);
    padding: 10px;
    box-shadow: none;
    margin-bottom: 10px;
    color: white;
}
#cards_landscape_wrap-2 {
    text-align: center;
}
.newsletter-page-title{
    font-weight: 700;
    margin: 10px 0 0 !important;
}
.text-container{
    padding: 10px;
}
.text-container p{
    color: var(--theme-color);
}
@media screen and (max-width: 768px) {
    .newsletter-page-title {
        margin-top: 0px;
        margin: 0 !important;
    }
}


