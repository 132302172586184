.gallery-container1 {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-auto-rows: 240px;
}
.gallery-container1 > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description {
  display: none;
  position: absolute;
}

.gallery-container1 img {
  border-radius: 10px;
  padding: 5px;
}

.gallery-container1 > div:hover {
  transform: scale(1.1);
}
.gallery-container1 > div:hover .description {
  display: block;
  text-align: center;
}

.gallery-container1 > div:hover img {
  opacity: 0.7;
}
.gallery-container1 > div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: #ffffff;
}

