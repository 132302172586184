
.tabsec-container { 
    max-width: 1320px; 
    height: 100%;
    margin: 0 auto; 
    padding: 20px;
  }
  
  /* ------------------- */
  /* PEN STYLES      -- */
  /* ----------------- */
  
  /* MAKE IT CUTE ----- */
  .tabs-purple {
    position: relative;
    display: flex;
    min-height: 300px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }
  
  .tabby-tab {
    flex: 1;
  }
  
  .tabby-tab label {
    display: block;
    box-sizing: border-box;
    /* tab content must clear this */
    height: 50px;    
    padding: 10px;
    text-align: center;
    background: linear-gradient(to right, #3f9093, #59c5c9, #70b747);
    cursor: pointer;
    transition: background 0.5s ease;
    color: white;
    font-weight: 600;
  }
  
  .tabby-tab label:hover {
    background: #419194;
  }
  
  .tabby-content {
    position: absolute;
    
    left: 0; bottom: 0; right: 0;
    /* clear the tab labels */
    top: 40px;     
    padding: 20px;
    border-radius: 0 0 8px 8px;
    background: #b0e2bd;
    color: black;
    transition: 
      opacity 0.8s ease,
      transform 0.8s ease   ;
    
    /* show/hide */
      opacity: 0;
      transform: scale(0.1);
      transform-origin: top left;
    
  }
  .tabby-content h2{
    color: #000;
  }
  
  .tabby-content img {
    float: left;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  
  /* MAKE IT WORK ----- */
  
  .tabby-tab [type=radio] { display: none; }
  [type=radio]:checked ~ label {
    background: #b0e2bd;
    z-index: 2;
  }
  
  [type=radio]:checked ~ label ~ .tabby-content {
    z-index: 1;    
    /* show/hide */
      opacity: 1;
      transform: scale(1);
  }
 
  /* BREAKPOINTS ----- */
  @media screen and (max-width: 767px) {
    .tabs-purple { min-height: 400px;}
  }
  
  @media screen and (max-width: 480px) {
    .tabs-purple { min-height: 580px; }
    .tabby-tab label { 
      height: 60px;
    }
    .tabby-content { top: 60px; }
    .tabby-content img {
      float: none;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }