.tm_thumb ul {
    margin: 0;
    padding: 10px 0;
    position: absolute;
    z-index: 1;
    bottom: 0;
    display: block;
    left: 50%;
    transform: translateX(-50%);    
    opacity: 0;
}
.tm_thumb ul li {
    /* width: 30px;
    height: 30px; */
    /* border: 1px solid #fff;
    border-radius: 50%; */
    margin: 3px;
    padding: 7px;
    display: inline-block;
    font-size: 30px;
}
.tm_thumb a{
    color: #fff;
}
.tm_thumb:hover::after{
opacity: 1;
}
.tm_thumb:hover ul{
    opacity: 1;
}
.tm_thumb a:hover li{
    color: #007bff;
}