
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 300;
}
h1, 
h2,
h3 {
    font-weight: 400;
    color: #387710;
    margin-top: 0;
}
h1{
  font-size: 2rem;
  margin: 0;
}
a {
    color: #1792d2;
}

a:hover,
a:focus {
    color: #781f19;
}

strong {
    font-weight: 700;
}
.subtitle{
  font-size: 0.85rem;
  font-weight: 700;
  margin: 0;
  color: #1792d2;
  letter-spacing: 0.05em;
}
.article-title {
    font-size: 2rem;
    font-weight: 500;
}

.article-read-more,
.article-info {
    font-size: .875rem;
}

.article-read-more {
    color: #1792d2;
    text-decoration: none;
    font-weight: 700;
}
.article-read-more:hover,
.article-read-more:focus {
    color: #781f19;
    text-decoration: underline;
}
.article-info {
    margin: 2em 0;
}

.container-flex{
  max-width: 80vw;
  margin: 1% auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  margin-top: 2em;
  border-bottom: 1px solid black;
}

.article-image, .widget-image {
  max-width: 100%;
  display: block;
  width: 100%;
  border-radius: 5px;
}
main{
  max-width: 70%;
}
.article-body{
  width: 100%;
  text-align: justify;
}
.sidebar{
  max-width: 100%;
  padding-top: 1px;
  margin-top: 1px;
}

@media (max-width:1050px){
  .container-flex{
    flex-direction: column;
  }
  .site-title, .subtitle{
    width: 100%;
  }
  main{
    max-width: 100%;
  }
  .sidebar{
    max-width: 100%;
  }
  .widget-title {
    font-size: 13px;
}  
.scrollbar {
  width: 100% !important;
}
}

/* articles */
.article-featured {
    padding-bottom: 2em !important;
    margin: 1%;
    padding: 0 1em 2em;
}
.article-featured p{
    margin: auto;
    padding: 0 5px 0;
}
.article-featured h5{
margin-top: 5px;
color: #781f19;
padding: 0 5px 0;
}
.article-featured a{
  margin-bottom: 5px;
  padding: 0 5px 0;
}

.article-featured a:hover{
   color: blue;
}
.article-recent {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
}

.article-recent-main {
    order: 2;
}

.article-recent-secondary {
    order: 1;
}



.scrollbar
{
	margin-right: 20px;
	float: right;
	height: 75vh;
	width: 30%;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
  padding: 10px 1px;
}

.force-overflow
{
	min-height: 450px;
}
.widget-body {
  margin-bottom: 1px !important;
  text-align: justify;
  line-height: 21px;
  font-size: 16px;
}
.widget-title{
  font-size: 16px !important;
  font-weight: 500;
}
.sidebar-widget a {
  margin-bottom: 2px !important;
  padding-bottom: 5px;
}
.procontainer {
  display: flex !important;
  flex-direction: row;
}
.sidebar-1{
  width: 80vw;
}
.widget-title1{
  color: black;
  margin: 5px;
  font-weight: 500;
  padding-bottom: 10px;
}
.news-links {
  color: black;
  font-weight: 500;
  font-size: 15px;
}
.news-links a{
  gap: 10px
}
.moretext-note {
  display: none;
}
#more {display: none;}
#more1 {display: none;}
#more2 {display: none;}
#more3 {display: none;}
#more4 {display: none;}
#more5 {display: none;}
#more6 {display: none;}
#more7 {display: none;}
#more8 {display: none;}
#more9 {display: none;}

@media (min-width: 675px) {
  .article-recent {
      flex-direction: row;
      justify-content: space-between;
  }
  
  .article-recent-main {
      width: 68%;
  }
  .widget-title {
    font-size: 12px !important;
}
  .article-recent-secondary {
      width: 30%;
  }
}

@media(min-width: 992px) and (max-width:1199x) {
  .widget-title {
    font-size: 15px;
}
.widget-body {
  font-size: 15px;
}
.news-links {
  font-size: 14px;
}
}
@media(max-width:991px) {
  .widget-title {
    font-size: 14px;
}
.widget-body {
  font-size: 14px;
}
.news-links {
  font-size: 13px;
}
}

@media(min-width: 1117px) and (max-width:1199x) {
  .widget-title {
    font-size: 14px;
}
}
@media(min-width: 992px) and (max-width:1116x) {
  .widget-title {
    font-size: 13px !important;
}
}

